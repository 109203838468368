import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import { withPrefix } from "gatsby";

const styles = theme => ({
  container: {
      display: "flex",
      justifyContent: 'center',
      width: "100%"
  },
  aside: {
    [theme.breakpoints.up('lg')]: {
      marginLeft: theme.spacing.unit * 5,
    }
  },
  iconHeading: {
    textTransform: "capitalize",
    fontWeight: "bold",
    fontSize: "18px",
    marginTop: theme.spacing.unit
  },
  paragraph: {
    ...theme.typography.paragraph,
    marginTop: theme.spacing.unit,
    width: '150px',
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    }
  },
  tag: {
    ...theme.typography.paragraph,
    width: "150px",
    backgroundColor: "#2290ff",
    color: "white",
    fontSize: "15pt",
    textAlign: "center",
    display: 'table',
    padding: '2px',
    [theme.breakpoints.up('lg')]: {
      width: '200px',
    }
  },
  image: {
    width: "200px",
    height: "200px",
    maxWidth: '45%',
    maxHeight: '40vw',
    marginRight: '10px',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
    }
  }
});

export default withStyles(styles)(props => {
  const { classes, image, name, text, desig } = props;
  return (
    <div className={classes.container}>
      <img
        className={classes.image}
        src={withPrefix(image)}
        width={200}
      />
      <div className={classes.aside}>
        <div className={classes.tag}>{desig}</div>
        <Typography className={classes.iconHeading}>{name}</Typography>
        <Typography className={classes.paragraph}>{text}</Typography>
      </div>
    </div>
  );
});
