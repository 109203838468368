import React from "react";
import PropTypes from "prop-types";
import OurTeam from "../../containers/about/OurTeam";

const OurTeamPage = ({ classes }) => {
  return (
    <OurTeam
      classes={classes}
    />
  );
};

OurTeamPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default OurTeamPage;
