import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card";
import TabBar from "../../components/TabBar";
import { aboutFields } from "../../metadata";
import Layout from '../../components/layout';

const styles = theme => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing.unit * 4,
      marginRight: theme.spacing.unit * 4,
      marginTop: theme.spacing.unit * 2,
      marginBottom: theme.spacing.unit * 2
    },
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing.unit * 22,
      marginRight: theme.spacing.unit * 22,
      marginTop: theme.spacing.unit * 5,
      marginBottom: theme.spacing.unit * 5
    }
  },
  container: {
    marginTop: theme.spacing.unit * 10,
    marginBottom: theme.spacing.unit * 10,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between"
  },
  item: {
    paddingTop: theme.spacing.unit * 10
  },
  subheading: {
    ...theme.typography.subheading,
    textTransform: "uppercase",
    color: "black",
    fontSize: "40px",
    fontStyle: "normal",
    fontFamily: "Lato",
    lineHeight: "normal",
    fontWeight: 900,
    fontStretch: "normal",
    letterSpacing: "normal",
  },
  paragraph: {
    ...theme.typography.paragraph,
    paddingTop: theme.spacing.unit * 3,
    lineHeight: "1.8",
    listStyleType: "disc",
    color: "#777777",
    fontSize: "16px",
    fontStyle: "normal",
    fontFamily: "Lato",
  }
});

const OurTeam = ({ classes, location }) => {
  const styled = {
    backgroundImage: "linear-gradient(to right ,#407bdf, #3a70cb)",
    marginTop: "30px"
  };
  return (
    <Layout location={location}>
      <React.Fragment>
        <TabBar fields={aboutFields} />
        <div className={classes.root}>
          <div className={classes.subheading}>Our Team</div>
          <div className={classes.paragraph}>
            Primoko Management team is comprised of a combination of creative,
            visionary, and strategic minds. They extend their love for technology
            to Primoko family by cultivating a strong culture, and instilling
            passion in each one of us towards a common goal.
          </div>
          <Grid
            container
            justify="space-between"
            alignitems="center"
            className={classes.container}
            spacing={40}
          >
            <Grid item className={classes.item} sm={12} md={6}>
              <Card
                image="/team/gurnam.png"
                name="Gurnam Bedi"
                desig="CTO"
                text="With over 17 years of industry experience, Gurnam worked in small startups to large corporations before co-founding Primoko."
              />
            </Grid>
            <Grid style={{display: 'none'}} item className={classes.item} sm={12} md={6}>
              <Card
                image="/team/rubin.png"
                name="Dr. Rubin Sidhu"
                desig="COO"
                text="Before joining Primoko, Dr. Sidhu was the Managing Director of Hareon Solar - a large multinational solar energy company. At Hareon, Dr. Sidhu headed the US and South Asia solar projects business, and oversaw an investment portfolio of over $60 million, and 320 MW of solar projects."
              />
            </Grid>
            <Grid item className={classes.item} sm={12} md={6}>
              <Card
                image="/team/evan.png"
                name="Evan Eustace"
                desig="Advisor"
                text="Evan has spent over 20 years developing software in diverse fields including television and publishing, on different platforms including server, mobile, and desktop. The last 15 years Evan’s been working on education technology."
              />
            </Grid>
            <Grid item style={{display: 'none'}} className={classes.item} sm={12} md={6}>
              <Card
                image="/team/deepinder.png"
                name="Deepinder Singh"
                desig="CEO (India)"
                text="Deepinder founded the India development center in 2003 and has grown the company to a software powerhouse."
              />
            </Grid>
            <Grid item className={classes.item} sm={12} md={6}>
              <Card
                image="/team/steve.png"
                name="Steve King"
                desig="VP - Product Exp."
                text="Steve is a full-stack technologist, product designer and collaboration maven."
              />
            </Grid>
            <Grid item className={classes.item} sm={12} md={6}>
              <Card
                image="/team/jennifer.png"
                name="Jennifer Mullins"
                desig="VP - Design"
                text="Jenny is a product designer who worked at Yammer (acquired by Microsoft) , Zenefits and LearnUp before joining Primoko."
              />
            </Grid>
            <Grid style={{display: 'none'}} item className={classes.item} sm={12} md={6}>
              <Card
                image="/team/alex.jpg"
                name="Alex Lazar"
                desig="CEO (Romania)"
                text="With over 15 years of experience Alex is an industry expert and an accomplished Senior Architect. Contributor to a variety of frameworks and libraries Alex has been delivering high quality code and APIs, web, mobile and desktop applications for startups and Fortune500 companies around the world."
              />
            </Grid>
        </Grid>
        </div>
      </React.Fragment>
    </Layout>
  );
};
export default withStyles(styles)(OurTeam);
